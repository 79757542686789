import "./App.css"
import HomeComponent from "./HomeComponent"

function App() {
  return (
    <div className="App">
      <HomeComponent />
    </div>
  )
}

export default App
